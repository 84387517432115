/*! These fonts are provided by Adobe and are subject to these Terms of Use: http://www.adobe.com/products/eulas/tou_typekit. © 2009-2022 Adobe Systems Incorporated. All Rights Reserved. */

@font-face {
	font: {
		family: '_is';
		weight: 100;
		style: normal;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-thin.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 100;
		style: italic;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-thin-italic.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 200;
		style: normal;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-extralight.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 200;
		style: italic;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-extralight-italic.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 300;
		style: normal;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-light.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 300;
		style: italic;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-light-italic.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 400;
		style: normal;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-regular.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 400;
		style: italic;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-regular-italic.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 700;
		style: normal;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-bold.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 700;
		style: italic;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-bold-italic.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 900;
		style: normal;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-black.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 900;
		style: italic;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-black-italic.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 950;
		style: normal;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-ultrablack.woff2') format('woff2');
}

@font-face {
	font: {
		family: '_is';
		weight: 950;
		style: italic;
		display: swap;
	}
	src: local('☺'), url('../private/fonts/is-ultrablack-italic.woff2') format('woff2');
}
